export const INTEGRATIONTYPES = {
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  WHATSAPP: 'whatsapp',
  SHOPIFY: 'shopify',
  ECOMMERCE: 'ecommerce',
}

export const ECOMMERCETYPES = {
  WOOCOMMERCE: 'woocommerce',
}

export const STORETYPES = {
  ecomm: 'ecomm',
  legacy: 'legacy',
}
