import { icons } from 'common/UI/Icon'
export default {
  shopify: {
    guestDomain: '',
    zokoShopifyId: '',
    nativeDomain: '',
    host: '',
    nativeNonce: '',
    connectStoreLoader: false,
    installLoader: false,
    isConnected: false,
    showRetry: false,
  },
  facebook: {
    pagesList: [],
    userName: '',
    selectedPageId: '',
    loader: false,
    isConnected: false,
    connectionName: '',
    link: 'www.facebook.com/',
    connectionImg: '',
  },
  instagram: {
    pagesList: [],
    userName: '',
    selectedPageId: '',
    loader: false,
    isConnected: false,
    link: 'www.instagram.com/',
    connectionName: '',
    connectionImg: '',
  },
  whatsapp: {
    isConnected: true,
    link: 'www.wa.me/',
    connectionName: '',
    connectionImg: '',
  },
  woocommerce: {
    id: '',
    platform: 'woocommerce',
    name: '',
    baseUrl: '',
    consumerKey: '',
    consumerSecret: '',
    integrationErrors: null,
    confirmationModal: false,
    isConnected: false,
    loader: false,
  },
  ecommerce: [
    {
      id: '',
      platform: 'woocommerce',
      name: '',
      baseUrl: '',
      consumerKey: '',
      consumerSecret: '',
      integrationErrors: null,
      connectionImg: '',
      icon: icons.woocommerceLogo,
      isConnected: false,
    },
  ],
  pageLoader: true,
}
