import config from 'lib/config'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import { deepClone } from 'lib/utils/object'

import initialState from './initialState'
import { headerMapper, hostInfoMapper, userDataMapper } from './mapper'

const actions = {
  getHostInfo:
    ({ hostName, service }) =>
    async ({ setState }) => {
      try {
        const resp = await fetcher(
          `${config.BASE_API}/domainsettings/${hostName}`
        )

        const hostInfo = resp?.data
        setState({
          hostInfo: hostInfoMapper(hostInfo),
        })
      } catch (e) {
        logError(e, 'getHostInfo')
        service.toast('Failed to fetch store info', { type: 'error' })
      }
    },
  updateUserData:
    () =>
    async ({ setState }) => {
      try {
        const headers = headerMapper({ storeId: null })
        const userDataResp = await fetcher(`${config.BASE_API}/user`, {
          headers,
        })
        const userData = userDataMapper(userDataResp?.data)
        setState({
          userData,
          currentStoreId: userDataResp?.data?.default_store,
          storeList: deepClone(userDataResp?.data?.stores),
        })
      } catch (error) {
        logError(error, 'updateUserData')
      }
    },
  getStoreType:
    ({ storeId }) =>
    async ({ setState }) => {
      try {
        const authHeaders = headerMapper({
          storeId,
        })

        const resp = await fetcher(`${config.BASE_API}/integrations/type`, {
          headers: authHeaders,
        })

        setState({
          currentStoreType: resp?.data?.integration_type,
        })
      } catch (error) {
        logError(error, 'updateStoreType')
      }
    },
  fetchAgents:
    ({ headers }) =>
    async ({ setState }) => {
      try {
        const response = await fetcher(`${config.BASE_API}/agents`, { headers })
        setState({
          agents: response?.data,
        })
      } catch (error) {
        logError(error, 'fetchAgents')
        window.globals.service.toast('Failed to fetch agents', {
          type: 'error',
        })
      }
    },
  setCurrentStore:
    ({ headers, storeId }) =>
    async () => {
      const requestBody = {
        default_store: storeId,
      }
      try {
        const resp = await fetcher(`${config.BASE_API}/user`, {
          headers,
          method: 'POST',
          body: requestBody,
        })

        if (resp?.data?.success) {
          window.globals.service.toast('Switching organisation', {
            type: 'success',
          })
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      } catch (error) {
        logError(error, 'setCurrentStore')
        window.globals.service.toast('Failed to set default store', {
          type: 'error',
        })
      }
    },
  registerFCMToken:
    ({ token }) =>
    async () => {
      try {
        const requestBody = {
          client_token: token,
        }

        const resp = await fetcher(
          `${config.BASE_API}/notifications/subscribe`,
          {
            headers: {
              includeAuthToken: true,
            },
            method: 'POST',
            body: requestBody,
          }
        )

        window.console.log(resp)
      } catch (error) {
        logError(error, 'setCurrentStore')
        window.globals.service.toast('Failed to setup notification', {
          type: 'error',
        })
      }
    },
  showViewAs:
    (showViewAs) =>
    ({ setState }) => {
      setState({
        showViewAs,
      })
    },
  updateMqttConnection:
    (mqttConnected) =>
    ({ setState }) => {
      setState({
        mqttConnected,
      })
    },
  setMobile:
    (isMobile) =>
    ({ setState }) =>
      setState({ isMobile }),
  updateInternetStatus:
    (internetConnected) =>
    ({ setState }) => {
      if (!internetConnected) {
        window.globals?.service?.toast(
          'You are offline. Please check your connection',
          {
            id: 'offlineMessage',
            type: 'error',
            persist: true,
          }
        )
      }
      setState({
        internetConnected,
      })
    },
  updateUserProfile:
    ({ name, image_url }) =>
    ({ getState, setState }) =>
      setState({
        userData: { ...getState().userData, name: name, image: image_url },
      }),
  setPreset:
    () =>
    ({ getState, setState }) => {
      const { name, image } = getState().userData
      setState({
        preset: {
          name: name,
          image: image,
        },
      })
    },
  updateProfile:
    ({ headers }) =>
    async ({ setState, getState }) => {
      setState({
        profileLoader: true,
      })
      try {
        const { name, image } = getState()?.preset
        const resp = await fetcher(`${config.BASE_API}/profile`, {
          headers,
          body: {
            name: name,
            image_url: image,
          },
          method: 'PUT',
        })
        if (resp.data) {
          window?.globals?.service.toast(
            'Successfully updated agent information',
            {
              type: 'success',
            }
          )
          setState({
            userData: {
              ...getState().userData,
              name: name,
              image: image,
            },
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'updateProfile')
        window?.globals?.service.toast('Failed to update agent information', {
          type: 'error',
        })
      } finally {
        setState({
          profileLoader: false,
        })
      }
    },
  setName:
    (name) =>
    ({ getState, setState }) => {
      const { image } = getState().preset
      setState({
        preset: {
          name: name,
          image: image,
        },
      })
    },
  setImage:
    (image) =>
    ({ getState, setState }) => {
      const { name } = getState().preset
      setState({
        preset: {
          name: name,
          image: image,
        },
      })
    },
  setStorePreset:
    (currentStore) =>
    ({ setState }) => {
      setState({
        storePreset: {
          name: currentStore?.name || '',
          image: currentStore?.image || '',
        },
      })
    },
  updateAccount:
    ({ headers }) =>
    async ({ setState, getState }) => {
      setState({
        accountSettingsLoader: true,
      })
      try {
        const { name, image } = getState()?.storePreset
        const resp = await fetcher(`${config.BASE_API}/store-settings`, {
          headers,
          body: {
            name: name,
            image_url: image,
          },
          method: 'PUT',
        })
        if (resp.data) {
          window?.globals?.service.toast(
            'Successfully updated organization information',
            {
              type: 'success',
            }
          )
          // Replace current store name and image
          const { currentStoreId, storeList } = getState()
          const idx = storeList?.findIndex(({ id }) => id === currentStoreId)
          storeList[idx] = { ...storeList[idx], name: name, image: image }
          setState({
            storeList: storeList,
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'updateProfile')
        window?.globals?.service.toast(
          'Failed to update organization information',
          {
            type: 'error',
          }
        )
      } finally {
        setState({
          accountSettingsLoader: false,
        })
      }
    },
  setStoreName:
    (name) =>
    ({ getState, setState }) => {
      setState({
        storePreset: {
          ...getState().storePreset,
          name: name,
        },
      })
    },
  setStoreImage:
    (image) =>
    ({ getState, setState }) => {
      setState({
        storePreset: {
          ...getState().storePreset,
          image: image,
        },
      })
    },
  setAvatarUploading:
    (avatarImageUploading) =>
    ({ setState }) => {
      setState({
        avatarImageUploading,
      })
    },
  setStoreImageUploading:
    (storeImageUploading) =>
    ({ setState }) => {
      setState({
        storeImageUploading,
      })
    },
  setPasswordRequested:
    (passwordResetRequested) =>
    ({ setState }) => {
      setState({
        passwordResetRequested,
      })
    },
  setAuthLoader:
    (flag) =>
    ({ setState }) => {
      setState({
        authLoader: flag,
      })
    },
  clearUserData:
    () =>
    ({ setState, getState }) => {
      setState({
        ...initialState,
        hostInfo: getState().hostInfo,
      })
    },
  toggleAgentStatus:
    ({ headers, agentId, available }) =>
    async ({ setState, getState }) => {
      setState({
        agentStatusLoader: true,
      })
      try {
        const resp = await fetcher(
          `${config.BASE_API}/agent/${agentId}/available/${available}`,
          {
            headers,
            method: 'PUT',
          }
        )
        const { currentStoreId } = getState()
        if (resp?.data?.success) {
          setState({
            storeList: deepClone(
              getState().storeList.map((storeData) => {
                if (storeData?.id === currentStoreId) {
                  storeData.available = available
                }
                return storeData
              })
            ),
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'toggleAgentStatus')
        window?.globals?.service?.toast('Failed to update availability', {
          type: 'error',
        })
      } finally {
        setState({
          agentStatusLoader: false,
        })
      }
    },
  getAgentStatus:
    ({ headers }) =>
    async ({ setState, getState }) => {
      setState({
        agentStatusLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/agent-availability`, {
          headers,
        })
        if (resp?.data) {
          const { currentStoreId } = getState()
          const { Available } = resp?.data
          setState({
            storeList: deepClone(
              getState().storeList.map((storeData) => {
                if (storeData?.id === currentStoreId) {
                  storeData.available = Available
                }
                return storeData
              })
            ),
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'getAgentStatus')
      } finally {
        setState({
          agentStatusLoader: false,
        })
      }
    },
  getSubscription:
    ({ headers }) =>
    async ({ setState }) => {
      try {
        const resp = await fetcher(`${config.BASE_API}/billing/subscription`, {
          headers,
        })
        setState({
          subscriptionPlan: resp?.data,
        })
      } catch (err) {
        logError(err, 'getSubscription')
      }
    },
  forgotPassword:
    ({ email }) =>
    async ({ setState }) => {
      setState({
        authLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/user/reset-password`, {
          body: {
            email,
          },
        })
        if (resp?.data?.success) {
          window.console.log('Forgot password', resp?.data)
          setState({
            passwordResetRequested: true,
          })
        } else {
          window.console.error('Forgot password: invalid response', resp?.data)
          throw new Error('Invalid response')
        }
      } catch (e) {
        window.console.error('Forgot password: catch', e)
        logError(e, 'forgotPassword')
        window?.globals?.service?.toast('Failed to request forgot password', {
          type: 'error',
        })
      } finally {
        setState({
          authLoader: false,
        })
      }
    },
}

export default actions
