import { templateVariableRegex } from 'lib/utils/regexManager'
import { ctaButtonActionTypes } from 'modules/Apps/Templates/TemplateCreate/constants.js'

const templateComponentTypes = {
  HEADER: 'HEADER',
  BODY: 'BODY',
  FOOTER: 'FOOTER',
  BUTTONS: 'BUTTONS',
}

export const sortTemplateComponents = (components) => {
  const result = Object.values(templateComponentTypes).reduce(
    (res, type) => [
      ...res,
      ...components?.filter((item) => item?.type === type),
    ],
    []
  )
  return result
}

export const generateTemplateForm = (components = [], buttonParamsCount) => {
  //buttonParamsCount will be > 1 only for user editable buttons (QUICK_REPLY, URL -> dynamic_link)
  const _templateForm = []
  sortTemplateComponents(components)?.forEach((item) => {
    if (item.type === templateComponentTypes.HEADER) {
      _templateForm.push({
        position: _templateForm.length,
        type: item.type,
        value: '',
        format: item.format,
        error: '',
      })
    } else if (item.type !== templateComponentTypes.BUTTONS) {
      const variables = item?.text?.match(templateVariableRegex)
      variables?.length > 0 &&
        variables?.forEach(() => {
          _templateForm.push({
            position: _templateForm.length,
            type: item.type,
            value: '',
            error: '',
          })
        })
    } else {
      //isButtonTypeCta indicates the cta button type to detect the dynamic_link or dynamic_link_ext URL button

      const isButtonTypeCta =
        buttonParamsCount &&
        item?.buttons?.find((button) => button.type === 'URL')

      const filteredButtonsList = item?.buttons?.filter(
        (button) =>
          !['OPT_OUT_URL', 'QUICK_REPLY_OPT_OUT', 'PHONE_NUMBER'].includes(
            button.type
          )
      ) // filtering out the OPT_OUT_URL, QUICK_REPLY_OPT_OUT, PHONE_NUMBER from the template buttons list because only QUICK_REPLY, URL type buttons are editable for user

      filteredButtonsList?.forEach((button) => {
        const defaultBtnValue = isButtonTypeCta ? '' : button.label

        //typeofAction will come only in template create -> sample , where value of dynamic link should be a valid url
        const buttonValue =
          button.typeOfAction === ctaButtonActionTypes.DYNAMIC_LINK
            ? 'https://store.google.com/orderhistory'
            : defaultBtnValue

        if (
          button.type === 'QUICK_REPLY' ||
          (isButtonTypeCta && button.type === 'URL')
        ) {
          _templateForm.push({
            position: _templateForm.length,
            type: item.type,
            value: buttonValue,
            error: '',
          })
        }
      })
    }
  })
  return _templateForm
}
