import config from 'lib/config'
import logError from 'lib/logError'

var mixpanel = require('mixpanel-browser')

export const initializeMixpanel = () => {
  config.MIXPANEL && mixpanel.init(config.MIXPANEL)

  config.MIXPANEL_SECONDARY &&
    mixpanel.init(config.MIXPANEL_SECONDARY, {}, 'mixpanel_secondary')
}

const debugMode = window?.localStorage?.debugMode === '1'

const trackEvent = (module, eventName, payload = {}, hideToast = false) => {
  payload = {
    ...window?.globals?._logData,
    ...payload,
  }
  debugMode && window.console.log('TRACKEVENT:', eventName, payload)
  // if (config.ENV === 'development') return
  const showLogs = !hideToast && debugMode
  try {
    if (!Object.keys(trackEvent.modules).includes(module)) {
      showLogs &&
        window?.globals?.service?.toast(
          'TRACKEVENT: module not part of trackEvent.modules: ' + eventName,
          { type: 'error' }
        )
      return window.console.error(
        'TRACKEVENT: module not part of trackEvent.modules',
        eventName
      )
    }
    if (!Object.keys(trackEvent.events).includes(eventName)) {
      window.console.log(
        'Object.keys(trackEvent.events)',
        Object.keys(trackEvent.events),
        eventName
      )
      showLogs &&
        window?.globals?.service?.toast(
          'TRACKEVENT: eventName not part of trackEvent.events: ' + eventName,
          { type: 'error' }
        )
      return window.console.error(
        'TRACKEVENT: eventName not part of trackEvent.eventName',
        eventName
      )
    }

    if (module === trackEvent.modules.secondaryAccount) {
      config.MIXPANEL_SECONDARY &&
        mixpanel?.mixpanel_secondary?.track?.(eventName, payload)
    } else if (config.MIXPANEL) {
      mixpanel?.track(eventName, payload)
    }
    showLogs && window?.globals?.service?.toast(eventName)
  } catch (error) {
    logError(error, 'trackEvent', {
      eventName,
      module,
    })
  }
}

trackEvent.modules = {
  auth: 'auth',
  chat: 'chat',
  dock: 'dock',
  header: 'header',
  queue: 'queue',
  quickfind: 'quickfind',
  service: 'service',
  socket: 'socket',
  fetcher: 'fetcher',
  redux: 'redux',
  secondaryAccount: 'secondaryAccount',
  settings: 'settings',
}

/**
 * component_action
 * e.g: conversation_click
 **/
trackEvent.events = {
  conversation_filter_change: 'conversation_filter_change',
  conversation_filter_clear: 'conversation_filter_clear',
  conversation_select: 'conversation_select',
  conversation_search_filter_click: 'conversation_search_filter_click',
  conversation_search: 'conversation_search',
  conversation_search_clear: 'conversation_search_clear',
  conversation_manage_chat: 'conversation_manage_chat',
  view_as_change: 'view_as_change',
  conversation_search_result_select: 'conversation_search_result_select',
  chatwindow_header_name_click: 'chatwindow_header_name_click',
  chatwindow_header_tag_click: 'chatwindow_header_tag_click',
  chatwindow_assign_to_change: 'chatwindow_assign_to_change',
  chatwindow_options_click: 'chatwindow_options_click',
  chatwindow_mobile_back_click: 'chatwindow_mobile_back_click',
  chatreply_quick_option_click: 'chatreply_quick_option_click',
  chatreply_quick_option_close: 'chatreply_quick_option_close',
  chatreply_quick_option_change: 'chatreply_quick_option_change',
  chatreply_quick_template_select: 'chatreply_quick_template_select',
  chatreply_quick_reply_select: 'chatreply_quick_reply_select',
  chatreply_expiry_template_select: 'chatreply_expiry_template_select',
  chatreply_private_note_click: 'chatreply_private_note_click',
  chatreply_file_upload: 'chatreply_file_upload',
  chatreply_file_upload_complete: 'chatreply_file_upload_complete',
  chatreply_attachment_delete: 'chatreply_attachment_delete',
  chatreply_emoji: 'chatreply_emoji',
  chatreply_emoji_toggle: 'chatreply_emoji_toggle',
  chatreply_text_focus: 'chatreply_text_focus',
  chatreply_send_message: 'chatreply_send_message',
  chatreply_send_blocked: 'chatreply_send_blocked',
  chatreply_audio_upload_audio: 'chatreply_audio_upload_audio',
  chatreply_audio_action: 'chatreply_audio_action',
  template_edit_post_click: 'template_edit_post_click',
  template_edit_cancel_click: 'template_edit_cancel_click',
  template_modal_close: 'template_modal_close',
  template_advanced_option_toggle: 'template_advanced_option_toggle',
  conversation_list_refresh_toast: 'conversation_list_refresh_toast',

  // DOCK:START
  dock_click_dock_tab: 'dock_click_dock_tab',
  dock_customer_profile_add_address: 'dock_customer_profile_add_address',
  dock_customer_profile_add_new_tag: 'dock_customer_profile_add_new_tag',
  dock_customer_profile_edit_name: 'dock_customer_profile_edit_name',
  dock_customer_profile_edit_name_cancel:
    'dock_customer_profile_edit_name_cancel',
  dock_customer_profile_edit_name_save: 'dock_customer_profile_edit_name_save',
  dock_customer_profile_sections_click: 'dock_customer_profile_sections_click',
  dock_customer_profile_media_tabs_click:
    'dock_customer_profile_media_tabs_click',
  dock_customer_profile_media_click: 'dock_customer_profile_media_click',
  dock_tags_sections_click: 'dock_tags_sections_click',
  dock_tags_add_new_tag: 'dock_tags_add_new_tag',
  dock_tags_delete_tag: 'dock_tags_delete_tag',
  dock_tags_add_new_tag_tag_click: 'dock_tags_add_new_tag_tag_click',
  dock_tags_add_new_tag_button_click: 'dock_tags_add_new_tag_button_click',
  dock_orders_search: 'dock_orders_search',
  dock_orders_tab_change: 'dock_orders_tab_change',
  dock_orders_card_toggle: 'dock_orders_card_toggle',
  dock_orders_action_click: 'dock_orders_action_click',
  dock_products_cart_open: 'dock_products_cart_open',
  dock_products_cart_close: 'dock_products_cart_close',
  dock_products_cart_update: 'dock_products_cart_update',
  dock_products_cart_send_to_chat_click:
    'dock_products_cart_send_to_chat_click',
  dock_products_cart_checkout_click: 'dock_products_cart_checkout_click',
  dock_products_cart_checkout_close: 'dock_products_cart_checkout_close',
  dock_products_cart_checkout_order_summary_toggle:
    'dock_products_cart_checkout_order_summary_toggle',
  dock_products_cart_checkout_send_to_chat_click:
    'dock_products_cart_checkout_send_to_chat_click',
  dock_products_cart_checkout_process_order_click:
    'dock_products_cart_checkout_process_order_click',
  dock_products_cart_checkout_add_address_click:
    'dock_products_cart_checkout_add_address_click',
  dock_products_cart_checkout_select_address:
    'dock_products_cart_checkout_select_address',
  dock_products_cart_checkout_change_address:
    'dock_products_cart_checkout_change_address',
  dock_products_list_search: 'dock_products_list_search',
  dock_products_list_filter_toggle: 'dock_products_list_filter_toggle',
  dock_products_list_filter_update: 'dock_products_list_filter_update',
  dock_products_list_product_info_click:
    'dock_products_list_product_info_click',
  dock_products_list_add_to_cart_click: 'dock_products_list_add_to_cart_click',
  dock_products_list_send_to_chat_click:
    'dock_products_list_send_to_chat_click',
  dock_products_product_info_send_to_chat_click:
    'dock_products_product_info_send_to_chat_click',
  dock_products_product_info_add_to_cart_click:
    'dock_products_product_info_add_to_cart_click',
  dock_products_product_info_filter_update:
    'dock_products_product_info_filter_update',
  dock_products_product_info_gallery_send_to_chat:
    'dock_products_product_info_gallery_send_to_chat',
  // DOCK:END

  storelist_store_switch: 'storelist_store_switch',
  storecontent_link_click: 'storecontent_link_click',
  maintabs_tab_change: 'maintabs_tab_change',

  agentteamlist_search: 'agentteamlist_search',
  agentteamlist_tab_change: 'agentteamlist_tab_change',
  agentteamlist_assign_change: 'agentteamlist_assign_change',
  queuelist_select_conversation: 'queuelist_select_conversation',
  queuelist_close_chat: 'queuelist_close_chat',
  chatpreview_goto_chat: 'chatpreview_goto_chat',
  queuelist_toggle_chatpreview: 'queuelist_toggle_chatpreview',
  chatwindowassign_previewmode_change: 'chatwindowassign_previewmode_change',
  queue_refresh_list_toast: 'queue_refresh_list_toast',

  api_call: 'api_call',
  redux_action: 'redux_action',
  header_store_info_click: 'header_store_info_click',
  header_mobile_menu_click: 'header_mobile_menu_click',

  internet_offline_toast: 'internet_offline_toast',
  sleep_toast: 'sleep_toast',

  // settings
  shopify_merger: 'shopify_merger',
  shopify_merger_mismatch: 'shopify_merger_mismatch',
  // service
  app_crash: 'app_crash',

  // annoucement
  show_pricing_alert: 'show_pricing_alert',
  stop_subscription: 'stop_subscription',
  accept_subscription: 'accept_subscription',

  enable_cod_button: 'enable_cod_button',
  ignore_cod_button: 'ignore_cod_button',
  show_cod_news: 'show_cod_news',

  enable_seg_button: 'enable_seg_button',
  ignore_seg_button: 'ignore_seg_button',
  show_seg_news: 'show_seg_news',

  enable_blling_alert_button: 'enable_blling_alert_button',
  ignore_blling_alert_button: 'ignore_blling_alert_button',
  show_blling_alert_news: 'show_blling_alert_news',

  show_sync_news: 'show_sync_news',
  ignore_sync_button: 'ignore_sync_button',

  enable_broadcast_button: 'enable_broadcast_button',
  show_broadcast_news: 'show_broadcast_news',
  ignore_broadcast_button: 'ignore_broadcast_button',
  learn_more_broadcast_button: 'learn_more_broadcast_button',

  down_time_sept_show: 'down_time_sept_show',
  down_time_sept_accept: 'down_time_sept_accept',
  down_time_sept_ignore: 'down_time_sept_ignore',

  incident_oct_show: 'incident_oct_show',
  incident_oct_accept: 'incident_oct_accept',
  incident_oct_ignore: 'incident_oct_ignore',

  incident_oct_meta_show: 'incident_oct_meta_show',
  incident_oct_meta_accept: 'incident_oct_meta_accept',
  incident_oct_meta_ignore: 'incident_oct_meta_ignore',

  // login - secondaryAccount events
  log_in: 'log_in',
  log_out: 'log_out',

  // checkout - scondaryAccount events
  checkout_sent: 'checkout_sent',
}

export default trackEvent
