export default {
  hostInfo: {},
  userData: null,
  currentStoreId: null,
  currentStoreType: null,
  agents: [],
  showViewAs: false,
  mqttConnected: false,
  isMobile: false,
  internetConnected: true,
  preset: {
    name: '',
    image: '',
  },
  storePreset: {
    name: '',
    image: '',
  },
  avatarImageUploading: false,
  profileLoader: false,
  storeImageUploading: false,
  accountSettingsLoader: false,
  passwordResetRequested: false,
  authLoader: false,
  storeList: [],
  agentStatusLoader: false,
  subscriptionPlan: {},
}
