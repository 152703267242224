// Function to escape special characters for regex

const regexEscape = (str) => {
  return str?.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

const removeProtocols = (url) => {
  return url?.replace(/^(?:https?:\/\/)?/i, '')
}

const isProtocolPresent = (url) => {
  return /^https?:\/\//i.test(url)
}

const removeSpaces = (str, char = '') => {
  return str.replace(/\s+/g, char)
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const urlRegex =
  // eslint-disable-next-line no-useless-escape
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+@,]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_=]*)?$/i

const validateEmail = (email) => {
  return emailRegex.test(String(email).toLowerCase())
}

const templateVariableRegex = /{{([0-9]+)}}/g // uses for positive integer values as template variables eg: {{1}}, {{2}}
// /{{[0-9].?}}/g

const templateVariablePreviewRegex = /\{{(.+?)\}}/g // use to allow {{OPT_OUT_LINK}} as a template variable
// /{{(.*?)}}/g

const validateUrl = (url) => {
  return urlRegex.test(String(url).toLowerCase())
}

const validateLiquidVariable = (val) => {
  return templateVariablePreviewRegex?.test(String(val))
}
export {
  regexEscape,
  removeProtocols,
  isProtocolPresent,
  removeSpaces,
  validateEmail,
  templateVariableRegex,
  templateVariablePreviewRegex,
  validateUrl,
  validateLiquidVariable,
}
