import sizeof from 'object-sizeof'
import { createStore, createHook, defaults } from 'react-sweet-state'

import trackEvent from 'lib/trackEvent'

import actions from './actions'
import initialState from './initialState'
import { headerMapper } from './mapper'

defaults.devtools = true

const Store = createStore({
  name: 'ZOKO-auth-' + window.location.origin,
  initialState,
  actions,
})

const logger = (storeState) => (next) => (arg) => {
  const result = next(arg)
  trackEvent(
    trackEvent.modules.redux,
    trackEvent.events.redux_action,
    {
      name: storeState?.key,
      action_name: storeState?.mutator?.actionName,
      payload_size: sizeof(arg),
    },
    true
  )
  return result
}

// disabled logs for mixpanel
!window && defaults.middlewares.add(logger)

export const useHostInfo = createHook(Store, {
  selector: (state) => state.hostInfo,
})

export const useAuth = createHook(Store, {
  selector: (state) => state.userData,
})

export const useAuthHeaders = createHook(Store, {
  selector: (state) => {
    return headerMapper({
      storeId: state.currentStoreId,
    })
  },
})

export const useStoreList = createHook(Store, {
  selector: (state) => ({
    storeList: state?.storeList,
    currentStoreId: state?.currentStoreId,
  }),
})

export const useAgents = createHook(Store, {
  selector: (state) => ({
    agents: state?.agents,
    headers: headerMapper({
      storeId: state.currentStoreId,
    }),
    showViewAs: state?.showViewAs,
  }),
})

export const useCurrentStoreId = createHook(Store, {
  selector: (state) => {
    return state?.currentStoreId
  },
})
export const useCurrentStoreType = createHook(Store, {
  selector: (state) => {
    return state?.currentStoreType
  },
})

export const useCurrentStoreName = createHook(Store, {
  selector: (state) => {
    const { currentStoreId, storeList } = state
    const currentStore = storeList.find(({ id }) => id === currentStoreId)
    return currentStore.name
  },
})

export const useCurrentStore = createHook(Store, {
  selector: (state) => ({
    currentStore: state?.storeList?.find(
      ({ id }) => id === state?.currentStoreId
    ),
    userData: state?.userData,
    subscriptionPlan: state?.subscriptionPlan,
  }),
})

export const useShowViewAs = createHook(Store, {
  selector: (state) => state.showViewAs,
})

export const useRole = createHook(Store, {
  selector: (state) =>
    state?.storeList?.find(({ id }) => id === state?.currentStoreId)?.role,
})

export const useMqttConnectionStatus = createHook(Store, {
  selector: (state) => state.mqttConnected,
})

export const useMobile = createHook(Store, {
  selector: (state) => state.isMobile,
})

export const useInternetStatus = createHook(Store, {
  selector: (state) => state.internetConnected,
})

export const useUserDataPreset = createHook(Store, {
  selector: (state) => state.preset,
})

export const useAvatarImageUploading = createHook(Store, {
  selector: (state) => ({
    avatarImageUploading: state.avatarImageUploading,
    profileLoader: state.profileLoader,
  }),
})

export const useStoreImageUploading = createHook(Store, {
  selector: (state) => ({
    storeImageUploading: state.storeImageUploading,
    accountSettingsLoader: state.accountSettingsLoader,
  }),
})

export const useAccountDataPreset = createHook(Store, {
  selector: (state) => ({
    storeName: state.storePreset?.name,
    storeImage: state.storePreset?.image,
  }),
})

export const useForgotPasswordData = createHook(Store, {
  selector: (state) => state.passwordResetRequested,
})

export const useAuthLoader = createHook(Store, {
  selector: (state) => state.authLoader,
})
