import { createStore, createHook, defaults } from 'react-sweet-state'

import actions from './actions'
import initialState from './initialState'

defaults.devtools = true

const Store = createStore({
  name: 'ZOKO-integrations-' + window.location.origin,
  initialState,
  actions,
})

export const useConnectStepsData = createHook(Store, {
  selector: (state) => ({
    guestDomain: state.shopify.guestDomain,
  }),
})

export const useZokoShopifyId = createHook(Store, {
  selector: (state) => state.shopify.zokoShopifyId,
})

export const useNativeDomain = createHook(Store, {
  selector: (state) => state.shopify.nativeDomain,
})

export const useShopifyHost = createHook(Store, {
  selector: (state) => state.shopify.host,
})

export const useShopifyNativeNonce = createHook(Store, {
  selector: (state) => state.shopify.nativeNonce,
})

export const useShopifyConnectLoader = createHook(Store, {
  selector: (state) => state.shopify.connectStoreLoader,
})

export const useShopifyPaymentURL = createHook(Store, {
  selector: (state) => ({
    paymentUrlLoader: state.shopify.paymentUrlLoader,
    paymentUrl: state.shopify.paymentUrl,
    showRetry: state.showRetry,
  }),
})

export const useFacebookConnect = createHook(Store, {
  selector: ({ facebook }) => facebook,
})

export const useLoader = createHook(Store, {
  selector: (state) => state.pageLoader,
})

export const useInstagramConnect = createHook(Store, {
  selector: ({ instagram }) => instagram,
})

export const useEcommerceConnect = createHook(Store, {
  selector: (state, type) => state[type],
})
export const useEcommerceStores = createHook(Store, {
  selector: (state) => state.ecommerce,
})
export const useMessengerAppConnect = createHook(Store, {
  selector: (state, channelKey) => state[channelKey],
})

export const useShopifyInstallLoader = createHook(Store, {
  selector: ({ shopify }) => shopify.installLoader,
})

export const useShopifyConnect = createHook(Store, {
  selector: ({ shopify }) => shopify,
})

export const useWhatsappConnect = createHook(Store, {
  selector: (state) => state.whatsapp,
})
