import React, { Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { ChatScreenSkeleton } from 'common/UI/LoadingSkeleton'
import routes from 'lib/config/routes'
// import registerServiceWorker from 'lib/utils/registerServiceWorker'
import Authenticator from 'modules/Auth/Authenticator'
import ShopifyInstall from 'modules/Auth/ShopifyInstall'
import ShopifyWelcome from 'modules/Auth/ShopifyWelcome'
import Widget from 'modules/Auth/Widget/Widget'
import { useHostInfo } from 'modules/Auth/store'
import Service from 'modules/Service'
// import DownTime from 'modules/Service/Announcement/DownTime'
import { useService } from 'modules/Service/store'

import './App.module.scss'

const Chat = React.lazy(() => import('./modules/Chat'))
const Overview = React.lazy(() => import('./modules/Overview'))
const Queue = React.lazy(() => import('./modules/Queue'))
const Settings = React.lazy(() => import('./modules/Settings'))
const Login = React.lazy(() => import('./modules/Auth/Login'))
const Apps = React.lazy(() => import('./modules/Apps'))
const ForgotPassword = React.lazy(() => import('./modules/Auth/ForgotPassword'))
const Flows = React.lazy(() => import('./modules/Flows'))
const Signup = React.lazy(() => import('./modules/Auth/SignUp'))
const SignOut = React.lazy(() => import('./modules/Auth/SignOut'))

const AppClip = () => (
  <div>
    App Clip
    <Helmet>
      <meta
        name="apple-itunes-app"
        content="app-id=1641010030, app-clip-bundle-id=io.zoko.AppClipProject.Clip, app-clip-display=card"
      />
    </Helmet>
  </div>
)

// registerServiceWorker()

const App = () => {
  const service = useService()
  const [hostInfo, actions] = useHostInfo()

  useEffect(() => {
    const hostName = window.location.hostname
    actions.getHostInfo({ hostName, service })
  }, [actions, service])

  const metaTags = hostInfo ? (
    <Helmet>
      <title>{hostInfo?.pageTitle}</title>
      <link rel="icon" href={hostInfo?.faviconUrl} />
    </Helmet>
  ) : null

  return (
    <>
      {metaTags}
      <Router>
        <Suspense fallback={<ChatScreenSkeleton />}>
          <Switch>
            <Route path={routes.LOGIN}>
              <Login />
            </Route>
            <Route path={routes.FORGOT_PASSWORD}>
              <ForgotPassword />
            </Route>
            <Route path={routes.SIGNUP}>
              <Signup />
            </Route>
            <Route path={routes.WIDGET}>
              <Widget />
            </Route>
            <Route path={routes.SHOPIFY_WELCOME}>
              <ShopifyWelcome />
            </Route>
            <Route path={routes.SHOPIFY_INSTALL}>
              <ShopifyInstall />
            </Route>
            <Route path={routes.APPCLIP}>
              <AppClip />
            </Route>
            <Authenticator>
              {/* <DownTime /> */}
              <Switch>
                <Route path={routes.FLOWS}>
                  <Flows />
                </Route>
                <Route path={routes.APPS}>
                  <Apps />
                </Route>
                <Route path={routes.SETTINGS}>
                  <Settings />
                </Route>
                <Route path={routes.QUEUE}>
                  <Queue />
                </Route>
                <Route path={routes.SIGNOUT}>
                  <SignOut />
                </Route>
                <Route path={routes.CHAT}>
                  <Chat />
                </Route>
                <Route path={routes.OVERVIEW}>
                  <Overview />
                </Route>
              </Switch>
            </Authenticator>
          </Switch>
        </Suspense>
      </Router>
      <Service />
    </>
  )
}

export default App
